var IDENTITY_VERIFICATION_BANNER_STRINGS = {
  title: 'Get Your ID ready',
  passport: 'US Passport',
  license: 'US Driver License',
  visa: 'US Visa',
  permanent: 'US Permanent Resident Card',
  state: 'US State ID Card',
  ead: 'US EAD',
  completed: 'Completed!'
};

export { IDENTITY_VERIFICATION_BANNER_STRINGS };
