import { useSelector } from 'react-redux';
import { usePersona } from '@belong/common';
import { IconSpinner } from '@belong/icons';
import { IdentityVerificationBanner } from '@belong/ui';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { UserIdentityBannerStep } from 'accounts/components/user-identity-banner-step';
import { STEP_GATE_NAMES } from 'accounts/constants/steps';
import { USER_IDENTITY_STRINGS } from 'accounts/constants/strings/user-identity.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import { isStepCompleted, isStepUnHidden } from 'accounts/utils/flow-steps';
import { useUserIdentityVerification } from 'hooks/useUserIdentityVerification';
import { selectUser } from 'store/redux/user/selectors';

export function UserIdentity() {
  const { currentStepFormData, loading, setLoading, stepsByGate, handleSaveAndNext } = useSetupFlowStep();
  const user = useSelector(selectUser);
  const {
    hasFetchedIdentityVerification,
    isLoadingIdentityVerification,
    identityVerification,
    refetchIdentityVerification,
  } = useUserIdentityVerification(user?.id, true);
  const { isLoadingPersona, isVerifying, isWaitingSafeDelay, startVerification } = usePersona({});

  const isIdentified = identityVerification?.status === 'Passed';

  function onVerifyPress() {
    startVerification({
      inquiryId: identityVerification?.providerVerificationId,
      sessionToken: identityVerification?.sessionToken,
      onComplete: () => refetchIdentityVerification(),
    });
  }

  async function onNextPress() {
    setLoading(true);

    try {
      await handleSaveAndNext({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const submitText = isIdentified ? 'Next' : 'Verify My Identity';
  const submitCallback = isIdentified ? onNextPress : onVerifyPress;

  function getBannerStep() {
    const nextLevelProfileIsCompleted = stepsByGate?.[STEP_GATE_NAMES.NextLevelProfile]
      ?.filter(isStepUnHidden)
      ?.every(isStepCompleted);

    if (nextLevelProfileIsCompleted) {
      return <UserIdentityBannerStep />;
    }

    return undefined;
  }

  if (!currentStepFormData && !loading) {
    return null;
  }

  const isLoading =
    !hasFetchedIdentityVerification ||
    isLoadingIdentityVerification ||
    isLoadingPersona ||
    isVerifying ||
    isWaitingSafeDelay;

  return (
    <StepFormLayout
      title={USER_IDENTITY_STRINGS.title}
      subTitle={USER_IDENTITY_STRINGS.subtitle}
      bannerStep={getBannerStep()}
      showUnitAddress={false}
      onSubmit={submitCallback}
      getForm={() => (
        <div>
          {isLoading && (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white-translucent z-10">
              <div className="mx-auto">
                <IconSpinner width={40} />
              </div>
            </div>
          )}
          <IdentityVerificationBanner isIdentified={isIdentified} />
        </div>
      )}
      ctaProps={{ label: submitText, disabled: isLoading }}
      disableProgressBar
    />
  );
}
