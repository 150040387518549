import { MoneyRange, Money } from '@belong/ui';
import { MaintenanceModel, ReportDetailModel } from 'api/models';
import classNames from 'classnames/bind';
import Collapsible from 'components/Collapsible/Collapsible';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import Tooltip from 'components/Tooltip/Tooltip';
import { BackedByBelong } from 'containercomponents/MaintenanceStatus/MaintenanceCostDisplay/MaintenanceCostDisplay';
import { SplitInfoBox } from 'containercomponents/MaintenanceStatus/split-infobox/split-infobox';
import Space from 'corecomponents/Space/Space';
import { Flex, Box, Text } from 'design-system';
import { useModal } from 'hooks/useModal';
import { MaintenanceRequester } from 'models/enums';
import PriceTable, {
  getHomeownerBundlePriceSection,
} from 'pages/PostInspectionFlow/steps/Improvements/Approval/PriceTable/PriceTable';
import { formatString } from 'strings';
import styles from './GroupHomeownerReceiptCosts.module.css';

const cx = classNames.bind(styles);

type GroupHomeownerReceiptCostsProps = {
  groupTask: MaintenanceModel;
  report?: ReportDetailModel;
};

export const GroupHomeownerReceiptCosts = ({ groupTask, report }: GroupHomeownerReceiptCostsProps) => {
  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  const homeownerPayment = report.details?.group?.payments?.find((p) => p.paidBy === MaintenanceRequester.Homeowner);

  if (!homeownerPayment || !groupTask) return null;

  const residentPayment = report.details.group.payments.find((p) => p.paidBy === MaintenanceRequester.Resident);
  const belongPayment = report.details.group.payments.find((p) => p.paidBy === MaintenanceRequester.Belong);

  const isInvoiced = report.details.group.requestTypeDetails.hasPublishedInvoices;
  const { isLegacyInvoicing, financedPayment, result, payments, displayAsPriceRange, priceRange } = groupTask;

  const homeownerRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Homeowner');
  const residentRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Resident');
  const belongRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Belong');

  const isMonthly = homeownerPayment.paymentPlan === 'Monthly';
  const showMonthlyAmount = !isInvoiced && isMonthly && homeownerPayment.installmentCount;
  const isOnboardingImprovement =
    groupTask?.requestCategory === 'HomeOnboarding' && groupTask?.summary === 'Improvements';
  const hideItemsCost = !!(isOnboardingImprovement && groupTask?.isDoNotExceedGroup);

  const bundleSections = getHomeownerBundlePriceSection({
    bundles: report.details.bundles,
    priceRange: displayAsPriceRange ? priceRange : null,
    isInvoiced,
    hideItemsCost,
  });

  const groupPayment = payments.find((p) => p.paidBy === MaintenanceRequester.Homeowner);
  const financePlanId = groupPayment?.balances?.find((balance) => !!balance.currentFinanceId)?.currentFinanceId;
  const hasElegibleOpenBalance = !!groupPayment?.balances?.find((balance) => balance.isOpen && balance.isFinanceable);

  let costs;
  if (isInvoiced) {
    costs = {
      subtotal: 'actualAdjustmentApplied',
      discount: 'actualDiscountAmount',
      netTotal: 'actualNetTotal',
    };
  } else {
    costs = {
      subtotal: 'estimatedAdjustmentApplied',
      discount: 'estimatedDiscountAmount',
      netTotal: 'estimatedDiscountApplied',
      installmentEach: 'estimatedInstallmentEach',
    };
  }

  const homeownerSubtotal =
    homeownerPayment[costs.subtotal] +
    (residentPayment?.[costs.subtotal] || 0) +
    (belongPayment?.[costs.subtotal] || 0);

  const tooltip = (
    <Tooltip contentStyle={{ width: 280, textAlign: 'center', border: 'noone' }} className={cx('tooltip')}>
      {homeownerPayment.installmentCount === 1 ? '1% fee ($20 minimum)' : '1% fee/month ($20 minimum)'}
    </Tooltip>
  );

  const isSubtotalVisible =
    homeownerPayment[costs.discount] > 0 || report?.report?.subType !== 'ImprovementsRepairsInspection';

  const isDueUpfrontCostVisible =
    report?.report?.subType !== 'ImprovementsRepairsInspection' && financedPayment?.dueNow > 0 && !isInvoiced;

  const isDueNowVisible =
    !isInvoiced &&
    homeownerPayment.estimatedUpfrontAmount > 0 &&
    report?.report?.subType !== 'ImprovementsRepairsInspection';

  const totalCostCopy = isInvoiced
    ? 'Total'
    : report?.report?.subType === 'ImprovementsRepairsInspection'
    ? 'Never Exceed Total'
    : 'Est. Total';

  return (
    <Collapsible
      createMarginAndPaddingClassName={cx('margin-padding')}
      collapsed={false}
      title={isInvoiced ? 'Receipt' : 'Estimated Pricing'}
      inner={
        <div className={cx('content')}>
          <BackedByBelong showModal={isModalOpen} onClick={setModalOpen} onHide={setModalClose} />
          <Space />
          <div className={cx('maintenanceCostDisplay')}>
            <PriceTable nameHeader="Item" valueHeader="Amount" sections={[bundleSections]} />
            {isSubtotalVisible && (
              <>
                <div className={cx('underline')} />

                <NameValueTable
                  noBold
                  items={[
                    {
                      name: 'Subtotal',
                      value: displayAsPriceRange ? (
                        <MoneyRange
                          lower={priceRange.lowerBoundTotal}
                          upper={priceRange.upperBoundTotal}
                          format="DOLLARS_NO_CENTS"
                        />
                      ) : (
                        <Money value={homeownerSubtotal} format="DOLLARS_NO_CENTS" />
                      ),
                    },
                  ]}
                />

                {/* Deductions */}
                <NameValueTable
                  noBold
                  discountValue
                  items={[
                    {
                      name: 'Savings',
                      value: (
                        <span className="font-semibold text-green flex gap-xs">
                          - <Money format="DOLLARS_NO_CENTS" value={homeownerPayment[costs.discount]} />
                        </span>
                      ),
                    },
                  ]}
                />
              </>
            )}
            {(residentPayment?.[costs.subtotal] > 0 || residentRange?.upperBound > 0) && (
              <NameValueTable
                noBold
                discountValue
                items={[
                  {
                    name: 'Resident Responsibility',
                    value: displayAsPriceRange ? (
                      <div className="flex">
                        -
                        <MoneyRange
                          lower={residentRange.lowerBoundTotal}
                          upper={residentRange.upperBoundTotal}
                          format="DOLLARS_NO_CENTS"
                        />
                      </div>
                    ) : (
                      <>
                        -<Money value={residentPayment[costs.subtotal]} format="DOLLARS_NO_CENTS" />
                      </>
                    ),
                  },
                ]}
              />
            )}
            {(belongPayment?.[costs.subtotal] > 0 || belongRange?.upperBound > 0) && (
              <NameValueTable
                noBold
                discountValue
                items={[
                  {
                    name: 'Belong Responsibility',
                    value: displayAsPriceRange ? (
                      <div className="flex">
                        -
                        <MoneyRange
                          lower={belongRange.lowerBoundTotal}
                          upper={belongRange.upperBoundTotal}
                          format="DOLLARS_NO_CENTS"
                        />
                      </div>
                    ) : (
                      <>
                        -<Money value={belongPayment[costs.subtotal]} format="DOLLARS_NO_CENTS" />
                      </>
                    ),
                  },
                ]}
              />
            )}

            <div className={cx('underline')} />

            {isLegacyInvoicing ? (
              <>
                <NameValueTable
                  boldValue
                  items={[
                    {
                      name: 'Total',
                      value: <Money format="DOLLARS_NO_CENTS" value={homeownerPayment[costs.netTotal]} />,
                    },
                  ]}
                />
                {isInvoiced && isMonthly && (
                  <Flex justifyContent="flex-end">
                    <Text variant="body-responsive" mr="xs">
                      (w/ installment fees)
                    </Text>
                    {tooltip}
                  </Flex>
                )}
                {showMonthlyAmount && (
                  <>
                    <div className={cx('underline')} />
                    <NameValueTable
                      boldValue
                      items={[
                        {
                          name: (
                            <Flex>
                              <Box mr="xs">
                                {formatString(
                                  `With ${homeownerPayment.installmentCount}-Month <span>Payment Plan</span>`
                                )}
                              </Box>
                              {tooltip}
                            </Flex>
                          ),
                          value: (
                            <>
                              <Money format="DOLLARS_NO_CENTS" value={homeownerPayment[costs.installmentEach]} />
                              /mo.
                            </>
                          ),
                        },
                      ]}
                    />
                  </>
                )}
                {isDueNowVisible && (
                  <NameValueTable
                    boldValue
                    items={[
                      {
                        name: (
                          <Flex>
                            <Box mr="xs">Due Now</Box>
                          </Flex>
                        ),
                        value: (
                          <>
                            <Money format="DOLLARS_NO_CENTS" value={homeownerPayment.estimatedUpfrontAmount} />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
              </>
            ) : (
              <>
                <NameValueTable
                  boldValue
                  items={[
                    {
                      name: totalCostCopy,
                      value: displayAsPriceRange ? (
                        <MoneyRange
                          lower={homeownerRange.lowerBoundTotal}
                          upper={homeownerRange.upperBoundTotal}
                          format="DOLLARS_NO_CENTS"
                        />
                      ) : (
                        <Money value={homeownerPayment[costs.netTotal]} format="DOLLARS_NO_CENTS" />
                      ),
                    },
                  ]}
                />
                {isDueUpfrontCostVisible && (
                  <NameValueTable
                    boldValue
                    items={[
                      {
                        name: (
                          <Flex>
                            <Box mr="xs">Due Upfront</Box>
                          </Flex>
                        ),
                        value: (
                          <>
                            <Money format="DOLLARS_NO_CENTS" value={financedPayment?.dueNow} />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
                {!hideItemsCost && (
                  <div className="mt-sm">
                    <SplitInfoBox
                      financePlanId={financePlanId}
                      hasOpenBalance={hasElegibleOpenBalance}
                      isMaintenanceCompleted={result === 'Completed'}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      }
    />
  );
};
