export const HOME_LISTING_STRINGS = {
  title: 'Help us prepare a glowing listing.',
  'title.adopted_resident': "Let's make sure we are ready to list when your residents move out.",
  'banner_step.adopted_resident': 'Thanks for introducing us to your home and residents!',
  'banner_step.not_adopted_resident': 'Congrats! Complimentary Inspection',
  'banner_step.not_adopted_resident.unlocked': ' Unlocked!',
  'banner_step.adopted_resident.next_up': "Next up, let's be ready to list.",
  'banner_step.not_adopted_resident.next_up': "Next, let's unlock your Professional Listing.",
  banner_step_scheduled_inspection: 'Congrats! Your inspection is scheduled for',
  banner_step_scheduled_inspection_subtitle: 'Up next... unlock your professional listing',
  'love_about_home.section_title': 'What do you love about your home and neighborhood?',
  'love_about_home.placeholder': 'Details for our professionals to highlight',
  'how_long.title': 'How long would you be willing to offer your home in a lease?',
  'how_long.subtitle':
    'Rent Guarantee applies for the first year and will renew for subsequent quarters as long as the resident paid on time. ',
  'how_long.1_year': '1 Year',
  'how_long.2_years': '2 Years',
  'how_long.3_years': '3 Years',
  'how_long.custom': 'Custom',
  'how_long.12_months': '12 Months',
  'how_long.max_months': '{max} Months',
  lease_duration: 'Lease Duration',
  'lease_duration.description':
    "One of our most effective levers to maximize your annual earnings is to get a resident to sign a longer lease. This reduces vacancy and placement fees to you. Yes, that means we're encouraging you to pay us less!",
  'quirks.section_title': 'What might take some getting used to about living in your home? Any quirks?',
  'quirks.placeholder': 'Quirks',
  'neighborhood.section_title': 'What do you love about your neighborhood?',
  'about_neighbors.section_title': "What's your relationship like with your neighbors?",
  'about_neighbors.placeholder': 'Anything we should know? (OPTIONAL)',
  'banner_image_later.section_title': 'Have a great photo of the front of your home we can use?',
  'banner_image_later.section_subtitle':
    "Uploading a photo now allows us to start marketing your home as Coming Soon. Later, we'll do a full professional photoshoot and 3D Tour!",
  'banner_image.section_title': 'Great! Please try to capture the whole face of your home in frame.',
  'banner_image.section_subtitle':
    "Later, we'll replace this with photos from our inspection and professional photoshoot. ",
  'carousel.title': 'Here are some example photos to follow...',
};
