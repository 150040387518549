import SuccessPage from '../SuccessPage/SuccessPage';
import IdentityCheck from './IdentityCheck/IdentityCheckV2';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import { STEPS_DATA } from './common';

export const STEPS_CONFIG = {
  PERSONAL_INFORMATION: {
    ...STEPS_DATA.PERSONAL_INFORMATION,
    form: PersonalInformation,
  },

  IDENTITY_CHECK: {
    ...STEPS_DATA.IDENTITY_CHECK,
    form: IdentityCheck,
  },

  SUCCESS_PAGE: {
    ...STEPS_DATA.SUCCESS_PAGE,
    form: SuccessPage,
  },
};

export const STEPS = [STEPS_CONFIG.PERSONAL_INFORMATION, STEPS_CONFIG.IDENTITY_CHECK, STEPS_CONFIG.SUCCESS_PAGE];
