import { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BREAKPOINTS_WIDTHS, formatMoney, useWindowSize } from '@belong/common';
import { IconCheck } from '@belong/icons';
import { Button, Text, Image } from '@belong/ui';
import cx from 'clsx';
import Selector from 'components/Selector/Selector';
import { AutoSplitNotice } from 'containercomponents/PaymentMethods/NewPaymentTypeSelector/components/AutoSplitNotice';
import { PaymentNotice } from 'containercomponents/PaymentMethods/NewPaymentTypeSelector/components/PaymentNotice';
import PaymentMethodContainer from 'containers/PaymentMethodContainer/PaymentMethodContainer';
import {
  MaintenancePaymentPlan,
  MaintenancePaymentMethod,
  PaymentMethod,
  PaymentPreference,
  PaymentPriority,
  PaymentType,
} from 'models/enums';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { QuestionsOrConcernsModalFooter } from 'post-inspection/components/question-or-concern-modal-footer/question-or-concern-modal-footer';
import { STRINGS } from 'post-inspection/constants/strings';
import { fetchUserAutoSplitPaymentMethod, updateUserAutoSplitPaymentMethod } from 'store/redux/user/actions';
import { selectFullUser, selectUserAutoSplit } from 'store/redux/user/selectors';
import { getString } from 'strings';

export const PaymentMethodSplitStep = ({ onNextStepClick }) => {
  const {
    homeownerPayment,
    homeownerReportPayment,
    setIsSubmitting,
    onApprovalStepSubmit,
    onNext,
    isDoNotExceedGroup,
    isLegacyInvoicing,
  } = useContext(ApprovalContext);
  const fullUser = useSelector(selectFullUser);
  const isAutoSplitEnabled = useSelector(selectUserAutoSplit);
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isInvoicedByGroup = isLegacyInvoicing && isDoNotExceedGroup;

  const [selectedPaymentType, setSelectedPaymentType] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [autoSplitError, setAutoSplitError] = useState(null);

  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;

  const { groupDueNow, groupDueNowWithoutSplitIt } = homeownerReportPayment || {};

  const userPrimaryPaymentMethod = fullUser?.getPaymentMethodFor(
    PaymentPreference.Maintenance,
    PaymentPriority.Primary
  );

  const updateUserAutoSplit = async (isEnabled) => {
    try {
      await dispatch(updateUserAutoSplitPaymentMethod(isEnabled));
      return true;
    } catch (err) {
      console.error(err);
      if (err[0].message.includes('Yearly Payout Plan')) {
        setAutoSplitError('Split It payment method can not be enabled because you are in a Yearly Payout Plan.');
        setTimeout(() => {
          setAutoSplitError(null);
        }, 6000);
      }
      setIsSubmitting(false);
      return false;
    }
  };

  const handleDepositOrNext = async (data) => {
    const depositResponse = await onApprovalStepSubmit({ groupPayment: data });
    if (depositResponse) onNext();
  };

  const getPaymentMethod = () => {
    if (selectedPaymentType === PaymentType.AutoSplit) return null;

    return userPrimaryPaymentMethod.paymentMethod === PaymentMethod.Ach
      ? MaintenancePaymentMethod.Check
      : userPrimaryPaymentMethod.paymentMethod;
  };

  const handlePayNow = async () => {
    const payload = {
      ...homeownerPayment,
      deferPaymentUntilLease: null,
      installmentCount: 1,
      paymentPlan: MaintenancePaymentPlan.OneTime,
      paymentMethod: getPaymentMethod(),
    };

    const isAutoSplitUpdate = (selectedPaymentType === 'AutoSplit') !== isAutoSplitEnabled;

    if (isAutoSplitUpdate) {
      const updated = await updateUserAutoSplit(!isAutoSplitEnabled);

      if (!updated) return;
    }

    if (isInvoicedByGroup) {
      return selectedPaymentType === 'AutoSplit' ? onNextStepClick() : handleDepositOrNext(payload);
    }

    if (groupDueNow === 0) {
      return handleDepositOrNext(payload);
    } else {
      onNext();
    }
  };

  const isDisabled = () => {
    if (typeof selectedPaymentType === 'undefined') return true;
    if (selectedPaymentType !== PaymentType.AutoSplit && !userPrimaryPaymentMethod) {
      return true;
    }

    if (selectedPaymentType === PaymentType.AutoSplit) {
      return false;
    }
    return false;
  };

  useEffect(() => {
    const fetchAutoSplit = async () => {
      setIsLoading(true);
      try {
        await dispatch(fetchUserAutoSplitPaymentMethod());
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };
    fetchAutoSplit();
  }, []);

  return (
    <div className="flex flex-col items-center justify-between pt-xl sm:pt-3xl w-full">
      <div className="flex flex-col items-center w-full">
        <div className="text-center sm:max-w-[340px] md:max-w-[700px]">
          {(!isLegacyInvoicing || !isDoNotExceedGroup) && groupDueNow > 0 && (
            <div className="flex justify-center mb-2sm">
              <IconCheck width={20} className="text-green mr-2xs" />
              <Text fontWeight="semibold" variant="body">
                {getString(STRINGS['deposit-paid'], {
                  deposit: formatMoney(groupDueNow, 'DOLLARS_WITH_CENTS'),
                })}
              </Text>
            </div>
          )}
          <Text fontWeight="semibold" variant="h2">
            {getString(STRINGS['payment-method-step-title-split'])}
          </Text>
        </div>
        {!isInvoicedByGroup && (<div className="text-center pt-xs sm:max-w-[560px]">
          <Text>
            {
              STRINGS['payment-method-split-step-subtitle']
            }{' '}
          </Text>
        </div>)}
        <div className="mb-xl sm:mb-0 mt-xl w-full">
          <div className={cx('md:px-sm', typeof selectedPaymentType === 'undefined' ? 'pb-2xl' : '')}>
            <>
              <Selector
                fluid
                selected={selectedPaymentType}
                onSelect={(value) => setSelectedPaymentType(value)}
                buttonClassName="md:flex-col flex-row items-center"
                iconClassName="self-center"
                labelClassName="text-center w-auto"
                mobileTag
                buttons={[
                  {
                    label: (
                      <>
                        <p className="text-body font-semibold mt-xs md:mt-0 md:text-center text-left">
                          Deducted From Rent
                        </p>
                        <p className="text-p1 font-[400] mb-xs md:mb-0 md:text-center text-left">
                          Interest Applied ·{' '}
                          {isInvoicedByGroup
                            ? `${formatMoney(groupDueNow, 'DOLLARS_WITH_CENTS')} Deposit`
                            : 'Installments'}
                        </p>
                      </>
                    ),
                    icon: (
                      <Image
                        src="homeowner-earnings/split-it-tile.svg"
                        alt="Split it"
                        className="w-[70px] h-[70px] md:w-[96px] md:h-[96px] inline-block  mt-0 md:mt-[20px] object-contain"
                      />
                    ),
                    iconSelected: (
                      <Image
                        src="homeowner-earnings/split-it-tile-active.svg"
                        alt="Split it"
                        className="w-[70px] h-[70px] md:w-[96px] md:h-[96px] inline-block  mt-0 md:mt-[20px] object-contain"
                      />
                    ),
                    key: PaymentType.AutoSplit,
                    tag: 'Recommended',
                  },
                  {
                    label: (
                      <>
                        <p className="text-body font-semibold">Upon Completion</p>
                        <p className="text-p1 font-[400] mb-xs md:mb-0 md:text-center text-left">
                          {isInvoicedByGroup
                            ? `In Full · ${formatMoney(groupDueNowWithoutSplitIt, 'DOLLARS_WITH_CENTS')} Deposit`
                            : 'FREE · In Full'}
                        </p>
                      </>
                    ),
                    icon: (
                      <Image
                        src="homeowner-earnings/payment-tile.svg"
                        alt="Payment Methods"
                        className="w-[70px] h-[70px] md:w-[96px] md:h-[96px] inline-block  mt-0 md:mt-[20px] object-contain"
                      />
                    ),
                    iconSelected: (
                      <Image
                        src="homeowner-earnings/payment-tile-active.svg"
                        alt="Payment Methods"
                        className="w-[70px] h-[70px] md:w-[96px] md:h-[96px] inline-block  mt-0 md:mt-[20px] object-contain"
                      />
                    ),
                    key: PaymentType.Payment,
                  },
                ]}
              />
              {selectedPaymentType === PaymentType.Payment && (
                <div className="mb-xl">
                  <PaymentNotice />
                  <PaymentMethodContainer maintenanceOnly hasLabels={false} />
                </div>
              )}
              {selectedPaymentType === PaymentType.AutoSplit && <AutoSplitNotice />}
            </>
            {autoSplitError && <Text className="text-red text-[14px] mb-sm">{autoSplitError}</Text>}
          </div>
          <QuestionsOrConcernsModalFooter>
            <Button
              disabled={isDisabled() || isLoading}
              onClick={() => {
                setIsSubmitting(true);
                handlePayNow();
                setIsSubmitting(false);
              }}
              size={isMobile ? 'fluid' : groupDueNow ? 'default' : 'large'}
            >
              {isInvoicedByGroup
                ? selectedPaymentType === PaymentType.AutoSplit
                  ? STRINGS.next
                  : STRINGS.done
                : groupDueNow
                ? STRINGS.done
                : STRINGS.next}
            </Button>
          </QuestionsOrConcernsModalFooter>
        </div>
      </div>
    </div>
  );
};
