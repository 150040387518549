import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { usePersona } from '@belong/common';
import { Button, IdentityVerificationBanner } from '@belong/ui';
import Spinner from 'components/Spinner/Spinner';
import Space from 'corecomponents/Space/Space';
import { useUserIdentityVerification } from 'hooks/useUserIdentityVerification';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { selectUser } from 'store/redux/user/selectors';
import { RESIDENT_APPLICATION_STRINGS } from 'strings/resident-application.strings';
import { getResidentOnboardingStepPath, STEPS_DATA } from '../common';

function IdentityCheck() {
  const history = useHistory();
  const { user } = useSelector((state) => ({
    user: selectUser(state),
  }));

  const {
    hasFetchedIdentityVerification,
    isLoadingIdentityVerification,
    identityVerification,
    refetchIdentityVerification,
  } = useUserIdentityVerification(user.id, true);

  const { isLoadingPersona, isVerifying, isWaitingSafeDelay, startVerification } = usePersona({
    onScriptLoadError: () => {
      // TODO: Handle Error
    },
  });

  function onVerifyPress() {
    startVerification({
      inquiryId: identityVerification?.providerVerificationId,
      sessionToken: identityVerification?.sessionToken,
      onComplete: () => refetchIdentityVerification(),
    });
  }

  function onDonePress() {
    history.push(getResidentOnboardingStepPath(STEPS_DATA.SUCCESS_PAGE));
  }

  const isIdentified = identityVerification?.status === 'Passed';

  const isDone = !!isIdentified;
  const buttonText = isDone
    ? RESIDENT_APPLICATION_STRINGS.identity_verification.done_button
    : RESIDENT_APPLICATION_STRINGS.identity_verification.verify_button;
  const buttonCallback = isDone ? onDonePress : onVerifyPress;

  const isLoading =
    !hasFetchedIdentityVerification ||
    isLoadingIdentityVerification ||
    isLoadingPersona ||
    isVerifying ||
    isWaitingSafeDelay;

  return (
    <FormLayout>
      <FormLayout.FormLayoutHeader
        title={RESIDENT_APPLICATION_STRINGS.identity_verification.title}
        subtitle={RESIDENT_APPLICATION_STRINGS.identity_verification.subTitle}
      />
      {isLoading && <Spinner />}
      <IdentityVerificationBanner isIdentified={isIdentified} />
      <div className="flex justify-center">
        <Button onClick={buttonCallback} size={{ _: 'fluid', md: 'default' }} className="mt-xl">
          {buttonText}
        </Button>
      </div>
      <Space value="2xl" />
    </FormLayout>
  );
}
export default IdentityCheck;
